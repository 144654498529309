import { render, staticRenderFns } from "./ContentFormGiayChungNhan.vue?vue&type=template&id=00b94415&scoped=true"
import script from "./ContentFormGiayChungNhan.vue?vue&type=script&lang=js"
export * from "./ContentFormGiayChungNhan.vue?vue&type=script&lang=js"
import style0 from "./ContentFormGiayChungNhan.vue?vue&type=style&index=0&id=00b94415&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00b94415",
  null
  
)

export default component.exports